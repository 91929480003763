<template>
    <footer class="nav-bg">
        <nav class="my-container">
            <div class="left-side-bar"></div>
            <div class="content-bar">
                <div class="footer-up">
                    <div class="content">
                        <router-link :to="$store.getters.getLocaleLink('/hakkimizda')"
                            class="text-decoration-none title">
                            {{ $t("footer.about_us_title") }}
                        </router-link>
                        <p class="content-text">
                            {{ $t("footer.about_us_description") }}
                            <router-link :to="$store.getters.getLocaleLink('/hakkimizda')" class="content-text">
                                {{ $t("footer.about_us_link") }}
                            </router-link>
                        </p>
                    </div>

                    <div class="content">
                        <router-link :to="$store.getters.getLocaleLink('/blog')" class="title">
                            BLOG
                        </router-link>
                        <router-link
                            :to="$store.getters.getLocaleLink('/blog/dizi-ile-ingilizce-ogrenmek-ayrintili-rehber')"
                            class="content-text">
                            {{ $t("footer.blog1") }}
                        </router-link>
                        <router-link
                            :to="$store.getters.getLocaleLink('/blog/ingilizceyi-dizilerden-ogrenmek-isteyenler-icin-en-kolay-8-dizi')"
                            class="content-text">
                            {{ $t("footer.blog2") }}
                        </router-link>
                        <router-link
                            :to="$store.getters.getLocaleLink('/blog/ingilizce-dizi-ve-filmlerde-en-sik-kullanilan-5000-kelime')"
                            class="content-text">
                            {{ $t("footer.blog3") }}
                        </router-link>
                        <router-link
                            :to="$store.getters.getLocaleLink('/blog/a1-seviye-ingilizce-kelimeler-ve-anlamlari')"
                            class="content-text">
                            {{ $t("footer.blog4") }}
                        </router-link>
                        <router-link
                            :to="$store.getters.getLocaleLink('/blog/yds-yokdil-sinavlarinda-en-cok-cikmis-600-kelime-listesi')"
                            class="content-text">
                            {{ $t("footer.blog5") }}
                        </router-link>
                        <router-link :to="$store.getters.getLocaleLink('/nasil-calisir')" class="content-text">
                            {{ $t("footer.blog6") }}
                        </router-link>
                    </div>
                    <div class="content">
                        <router-link :to="$store.getters.getLocaleLink('/iletisim')" class="title">
                            {{ $t("footer.contact") }}
                        </router-link>
                        <!-- 
                        <div class="social-media-links">
                            <img style="width: 30px;" alt="Mail" src='@/assets/Footer/mail.svg' />
                            <a href="mailto: destek@diziyleogren.com"
                                class="content-text text-decoration-none">destek@diziyleogren.com</a>
                        </div>
                        -->
                        <span class="title">
                            {{ $t("footer.social_media") }}
                        </span>
                        <div class="social-media-links">
                            <a target="_blank" href="https://www.facebook.com/diziyleogren/">
                                <i class="fab fa-facebook-square text-light" style="font-size: 30px;"></i>
                            </a>
                            <a target="_blank" href="https://www.instagram.com/diziyleogren/">
                                <i class="fab fa-instagram-square text-light" style="font-size: 30px;"></i>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/diziyleogren">
                                <i class="fab fa-linkedin text-light" style="font-size: 30px;"></i>
                            </a>
                            <a target="_blank" href="https://twitter.com/diziyleogren">
                                <i class="fab fa-x-twitter text-light" style="font-size: 30px;"></i>
                            </a>
                        </div>
                        <a v-if="$i18n.locale != 'en'" target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.diziyleogren.diziyleogren">
                            <img class="mobile-badge" alt="Google Play'den alın"
                                src='@/assets/Home/google-play-badge.svg' />
                        </a>
                        <a v-if="$i18n.locale != 'en'" target="_blank"
                            href="https://apps.apple.com/tr/app/diziyleogren/id6444682457">
                            <img class="mobile-badge" alt="App Store'dan alın"
                                src='@/assets/Home/app-store-badge.svg' /></a>

                    </div>
                </div>
                <div class="footer-down">
                    <hr>
                    <div class="content">
                        <router-link :to="$store.getters.getLocaleLink('/gizlilikPolitikasi')"
                            class="text-decoration-none content-text">
                            {{ $t("footer.privacy_policy") }}
                        </router-link>

                        <p class="content-text">Copyright Diziyleogren.com © 2025. All Rights Reserved.</p>

                    </div>
                </div>

            </div>
            <div class="right-side-bar"></div>

        </nav>

    </footer>
</template>
<script></script>
<style scoped>
.nav-bg {
    background: #8887C6;
    background-image: url("~@/assets/Footer/footer-bg.svg");
    background-size: cover;
    padding-top: 24px;
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.my-container {
    margin: 0px;
}

.content-bar {
    display: flex;
    flex-direction: column;
}

.content-bar .footer-up {
    display: flex;
    flex-direction: row;
    gap: 72px;
}


.content-bar .footer-up .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 300px;
}

.content-bar .footer-up .content .title {
    color: var(--Gray-6, #F2F2F2);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}

.content-bar .footer-up .content .content-text {
    color: var(--Gray-6, #F2F2F2);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-bar .footer-up .content .social-media-links {
    display: flex;
    flex-direction: row;
    gap: 16px;
}


.content-bar .footer-down {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.content-bar .footer-down hr {
    color: #F2F2F2;
    margin: 0;
    margin-top: 16px;

}


.content-bar .footer-down .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content-bar .footer-down .content .content-text {
    color: var(--Gray-6, #F2F2F2);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.content-bar .footer-down hr {
    color: #F2F2F2;
    height: 2px;
}

.mobile-badge {
    width: 160px;
}

@media (max-width: 1000px) {
    .footer-up {
        flex-direction: column !important;
        gap: 32px !important;
    }

    .content-bar .footer-down .content {
        gap: 16px;
    }

    .content-bar .footer-down .content .content-text {
        font-size: 10px;
    }

}

@media (max-width: 1250px) {
    .content-bar .footer-up .content {
        min-width: 200px;
    }

}
</style>