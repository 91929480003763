import { createRouter, createWebHistory, RouterView } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    component: RouterView,
    children: [
      // --------------------  DYNAMIC PAGES -----------------------------------
      {
        path: "",
        name: "TRhome",
        component: Home,
      },
      {
        path: "dizi",
        name: "TRSeriesList",
        component: () =>
          import(
            /* webpackChunkName: "SeriesList" */ "../views/SeriesList.vue"
          ),
      },
      {
        path: "film",
        name: "TRMoviesList",
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MoviesList.vue"
          ),
      },
      {
        path: "dizi/:url",
        name: "TRSeriesDetail",
        component: () =>
          import(
            /* webpackChunkName: "SeriesDetail" */ "../views/SeriesDetail.vue"
          ),
      },
      {
        path: "film/:url",
        name: "TRMovieDetail",
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MovieDetail.vue"
          ),
      },
      {
        path: "auth",
        name: "TRauth",
        beforeEnter: (to, from, next) => {
          if (!store.getters.isAuthenticated) {
            next();
          } else {
            next("/hesabim");
          }
        },
        component: () =>
          import(/* webpackChunkName: "Login" */ "../views/Auth.vue"),
      },
      {
        path: "hesabim",
        name: "TRhesabim",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(/* webpackChunkName: "hesabim" */ "../views/Account.vue"),
      },
      {
        path: "liste/:url",
        name: "TRGeneratedList",
        component: () =>
          import(
            /* webpackChunkName: "GeneratedList" */ "../views/GeneratedList.vue"
          ),
      },
      {
        path: "quiz",
        name: "TRquiz",
        component: () =>
          import(/* webpackChunkName: "QuizMain" */ "../views/QuizMain.vue"),
      },
      {
        path: "iletisim",
        name: "TRiletisim",
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
      },

      {
        path: "premium/ingilizce-a1-kelime-listesi-pdf",
        name: "PremiumFiles",
        beforeEnter: (to, from, next) => {
          if (store.getters.getUserData.isPremium) {
            var link = document.createElement("a");
            link.href =
              "/premium/premiumFiles/İngilizce A1 Kelime Listesi - DiziyleOgrenCom.pdf";
            link.download = "İngilizce A1 Kelime Listesi - DiziyleOgrenCom.pdf";
            link.dispatchEvent(new MouseEvent("click"));
          } else {
            next("/premium");
          }
        },
      },
      {
        path: "premium/yds-kelime-listesi-pdf",
        name: "PremiumFiles2",
        beforeEnter: (to, from, next) => {
          if (store.getters.getUserData.isPremium) {
            var link = document.createElement("a");
            link.href =
              "/premium/premiumFiles/YDS Kelimeler - DiziyleOgrenCom.pdf";
            link.download = "YDS Kelimeler - DiziyleOgrenCom.pdf";
            link.dispatchEvent(new MouseEvent("click"));
          } else {
            next("/premium");
          }
        },
      },
      {
        path: "premium/5000-kelime-listesi-pdf",
        name: "PremiumFiles3",
        beforeEnter: (to, from, next) => {
          if (store.getters.getUserData.isPremium) {
            var link = document.createElement("a");
            link.href =
              "/premium/premiumFiles/En Çok Kullanılan İngilizce 5000 Kelime - DiziyleOgrenCom.pdf";
            link.download =
              "En Çok Kullanılan İngilizce 5000 Kelime - DiziyleOgrenCom.pdf";
            link.dispatchEvent(new MouseEvent("click"));
          } else {
            next("/premium");
          }
        },
      },
      {
        path: "premium",
        name: "TRpremium",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(/* webpackChunkName: "premium" */ "../views/Premium.vue"),
      },
      {
        path: "premium/isleniyor",
        name: "TRPremiumProcessing",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Processing.vue"
          ),
      },
      {
        path: "premium/odemehata",
        name: "TRPremiumError",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Error.vue"
          ),
      },
      {
        path: "hesabim/aktivasyon/:code",
        name: "TRAktivasyon",
        component: () =>
          import(
            /* webpackChunkName: "Aktivasyon" */ "../views/MailActivation.vue"
          ),
      },
      {
        path: "hesabim/sifre-yenile/:code",
        name: "TRPasswordRefresh",
        component: () =>
          import(
            /* webpackChunkName: "PasswordRefresh" */ "../views/PasswordRefresh.vue"
          ),
      },

      // --------------------  STATIC PAGES -----------------------------------

      {
        path: "hakkimizda",
        name: "hakkimizdaTR",
        component: () =>
          import(
            /* webpackChunkName: "About" */ "../views/StaticPages/tr/About.vue"
          ),
      },
      {
        path: "gizlilikPolitikasi",
        name: "gizlilikPolitikasiTR",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/StaticPages/tr/PrivacyPolicy.vue"
          ),
      },
      {
        path: "kullanimKosullari",
        name: "kullanimKosullariTR",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/StaticPages/tr/UseConditions.vue"
          ),
      },
      {
        path: "nasil-calisir",
        name: "NasilCalisirTR",
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/StaticPages/tr/UseInstructions.vue"
          ),
      },
      {
        path: "satin-alma-kosullari",
        name: "UseInstructionsTR",
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/StaticPages/tr/PurchaseConditions.vue"
          ),
      },
      {
        path: "en/about",
        name: "hakkimizdaEN",
        component: () =>
          import(
            /* webpackChunkName: "About" */ "../views/StaticPages/en/About.vue"
          ),
      },
      {
        path: "en/privacy-policy",
        name: "gizlilikPolitikasiEN",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/StaticPages/en/PrivacyPolicy.vue"
          ),
      },
      {
        path: "en/use-conditions",
        name: "kullanimKosullariEN",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/StaticPages/en/UseConditions.vue"
          ),
      },
      {
        path: "en/how-does-it-work",
        name: "NasilCalisirEN",
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/StaticPages/en/UseInstructions.vue"
          ),
      },
      {
        path: "en/terms-of-service",
        name: "UseInstructionsEN",
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/StaticPages/en/PurchaseConditions.vue"
          ),
      },
      {
        path: "es/about",
        name: "hakkimizdaES",
        component: () =>
          import(
            /* webpackChunkName: "About" */ "../views/StaticPages/es/About.vue"
          ),
      },
      {
        path: "es/privacy-policy",
        name: "gizlilikPolitikasiES",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/StaticPages/es/PrivacyPolicy.vue"
          ),
      },
      {
        path: "es/use-conditions",
        name: "kullanimKosullariES",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/StaticPages/es/UseConditions.vue"
          ),
      },
      {
        path: "es/how-does-it-work",
        name: "NasilCalisirES",
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/StaticPages/es/UseInstructions.vue"
          ),
      },
      {
        path: "es/terms-of-service",
        name: "UseInstructionsES",
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/StaticPages/es/PurchaseConditions.vue"
          ),
      },
      {
        path: "atiflar",
        name: "TRatiflar",
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/Atiflar.vue"
          ),
      },

      // --------------------  BLOGS  -----------------------------------

      {
        path: "blog",
        name: "blogTR",
        component: () =>
          import(/* webpackChunkName: "TRblog" */ "../views/Blog/Main.vue"),
      },
      {
        path: "blog/dizi-ile-ingilizce-ogrenmek-ayrintili-rehber",
        name: "blogRehberTR",
        component: () =>
          import(
            /* webpackChunkName: "BlogRehber" */ "../views/Blog/tr/Rehber.vue"
          ),
      },
      {
        path: "blog/ingilizceyi-dizilerden-ogrenmek-isteyenler-icin-en-kolay-8-dizi",
        name: "blogDizilerTR",
        component: () =>
          import(
            /* webpackChunkName: "blogDiziler" */ "../views/Blog/tr/Diziler.vue"
          ),
      },
      {
        path: "blog/ingilizce-dizi-ve-filmlerde-en-sik-kullanilan-5000-kelime",
        name: "blog5000KelimeTR",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/tr/5000Kelime.vue"
          ),
      },
      {
        path: "blog/a1-seviye-ingilizce-kelimeler-ve-anlamlari",
        name: "blogA1SeviyeKelimeTR",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/tr/A1Kelimeler.vue"
          ),
      },
      {
        path: "blog/yds-yokdil-sinavlarinda-en-cok-cikmis-600-kelime-listesi",
        name: "Yokdil600TR",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/tr/Yokdil600.vue"
          ),
      },
      {
        path: "en/blog/how-to-learn-english-with-tv-series",
        name: "blogRehberEN",
        component: () =>
          import(
            /* webpackChunkName: "BlogRehber" */ "../views/Blog/en/Rehber.vue"
          ),
      },
      {
        path: "en/blog/best-tv-series-for-learning-english",
        name: "blogDizilerEN",
        component: () =>
          import(
            /* webpackChunkName: "blogDiziler" */ "../views/Blog/en/Diziler.vue"
          ),
      },
      {
        path: "en/blog/tv-series-and-movies-vocabulary",
        name: "blog5000KelimeEN",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/en/5000Kelime.vue"
          ),
      },
      {
        path: "en/blog/a1-level-vocabulary",
        name: "blogA1SeviyeKelimeEN",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/en/A1Kelimeler.vue"
          ),
      },
      {
        path: "en/blog/english-exam-vocabulary",
        name: "Yokdil600EN",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/en/Yokdil600.vue"
          ),
      },
      {
        path: "es/blog/how-to-learn-english-with-tv-series",
        name: "blogRehberES",
        component: () =>
          import(
            /* webpackChunkName: "BlogRehber" */ "../views/Blog/es/Rehber.vue"
          ),
      },
      {
        path: "es/blog/best-tv-series-for-learning-english",
        name: "blogDizilerES",
        component: () =>
          import(
            /* webpackChunkName: "blogDiziler" */ "../views/Blog/es/Diziler.vue"
          ),
      },
      {
        path: "es/blog/tv-series-and-movies-vocabulary",
        name: "blog5000KelimeES",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/es/5000Kelime.vue"
          ),
      },
      {
        path: "es/blog/a1-level-vocabulary",
        name: "blogA1SeviyeKelimeES",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/es/A1Kelimeler.vue"
          ),
      },
      {
        path: "es/blog/english-exam-vocabulary",
        name: "Yokdil600ES",
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/es/Yokdil600.vue"
          ),
      },
    ],
  },

  // ---------------------------- DİĞER DİLLER ----------------------------------------------
  {
    path: "/:lang(en|es)",
    component: RouterView,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "tv-series",
        name: "SeriesList",
        component: () =>
          import(
            /* webpackChunkName: "SeriesList" */ "../views/SeriesList.vue"
          ),
      },
      {
        path: "movies",
        name: "MoviesList",
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MoviesList.vue"
          ),
      },
      {
        path: "tv-series/:url",
        name: "SeriesDetail",
        component: () =>
          import(
            /* webpackChunkName: "SeriesDetail" */ "../views/SeriesDetail.vue"
          ),
      },
      {
        path: "movies/:url",
        name: "MovieDetail",
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MovieDetail.vue"
          ),
      },
      {
        path: "auth",
        name: "auth",
        beforeEnter: (to, from, next) => {
          if (!store.getters.isAuthenticated) {
            next();
          } else {
            next("/account");
          }
        },
        component: () =>
          import(/* webpackChunkName: "Login" */ "../views/Auth.vue"),
      },
      {
        path: "account",
        name: "hesabim",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(/* webpackChunkName: "hesabim" */ "../views/Account.vue"),
      },
      {
        path: "wordlist/:url",
        name: "GeneratedList",
        component: () =>
          import(
            /* webpackChunkName: "GeneratedList" */ "../views/GeneratedList.vue"
          ),
      },
      {
        path: "quiz",
        name: "quiz",
        component: () =>
          import(/* webpackChunkName: "QuizMain" */ "../views/QuizMain.vue"),
      },
      {
        path: "contact",
        name: "iletisim",
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
      },
      {
        path: "blog",
        name: "blogMain",
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/Blog/Main.vue"),
      },
      {
        path: "premium",
        name: "premium",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(/* webpackChunkName: "premium" */ "../views/Premium.vue"),
      },
      {
        path: "premium/isleniyor",
        name: "PremiumProcessing",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Processing.vue"
          ),
      },
      {
        path: "premium/odemehata",
        name: "PremiumError",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Error.vue"
          ),
      },
      {
        path: "hesabim/aktivasyon/:code",
        name: "Aktivasyon",
        component: () =>
          import(
            /* webpackChunkName: "Aktivasyon" */ "../views/MailActivation.vue"
          ),
      },
      {
        path: "hesabim/sifre-yenile/:code",
        name: "PasswordRefresh",
        component: () =>
          import(
            /* webpackChunkName: "PasswordRefresh" */ "../views/PasswordRefresh.vue"
          ),
      },
    ],
  },

  // {
  //  path: "/youtube",
  //  name: "youtube",
  //  component: () =>
  //   import(/* webpackChunkName: "youtube" */ "../views/Youtube.vue"),
  // },
  // Yönlendirmeler
  { path: "/ingilizce-dizilerin-kelime-ve-anlamlari", redirect: "/dizi" },
  { path: "/ingilizce-filmlerin-kelime-ve-anlamlari", redirect: "/film" },
  { path: "/tanitim", redirect: "/nasil-calisir" },
  { path: "/hesap/kullanimKosullari", redirect: "/" },
  {
    // old https://diziyleogren.com/dizi/ingilizce/the-walking-dead-kelimeler-ve-anlamlari
    // new https://diziyleogren.com/dizi/the-walking-dead
    path: "/dizi/ingilizce/:oldURL",
    redirect: (to) => {
      return {
        path:
          "/dizi/" + to.params.oldURL.replace("-kelimeler-ve-anlamlari", ""),
      };
    },
  },

  {
    // old https://diziyleogren.com/film/ingilizce/forest-gump-kelimeler-ve-anlamlari
    // new https://diziyleogren.com/film/forest-gump
    path: "/film/ingilizce/:oldURL",
    redirect: (to) => {
      return {
        path:
          "/film/" + to.params.oldURL.replace("-kelimeler-ve-anlamlari", ""),
      };
    },
  },

  {
    // old https://diziyleogren.com/Liste/Listem/ilk1000
    // new https://diziyleogren.com/liste/ilk1000
    path: "/Liste/Listem/:oldURL",
    redirect: (to) => {
      return {
        path: "/liste/" + to.params.oldURL,
      };
    },
  },

  // 404 PAGE
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "Aktivasyon" */ "../views/404.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
