import { createI18n } from "vue-i18n";
import tr from "./locales/tr.json";
import en from "./locales/en.json";
import es from "./locales/es.json";

function loadLocaleMessages() {
  const locales = [{ tr: tr }, { en: en }, { es: es }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}
export default createI18n({
  locale: "tr",
  fallbackLocale: "en",
  legacy: false,
  messages: loadLocaleMessages(),
});
