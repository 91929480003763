import axios from "axios";
import router from "../../router/index";
import store from "..";

const state = {
  userData: "",
  scoreData: {},
  loginErrors: [],
  registerErrors: [],
  googleLoginErrors: [],
  googleRegisterErrors: [],
  googleToken: "",
  isGoogleLoginClick: false,
};
const getters = {
  // state verisini çağırmak için kullanılır
  getUserData(state) {
    return state.userData;
  },
  isAuthenticated(state) {
    return state.userData !== "";
  },
  getErrors: (state) => (type) => {
    if (type == "login") {
      return state.loginErrors;
    } else if (type == "register") {
      return state.registerErrors;
    } else if (type == "google_login") {
      return state.googleLoginErrors;
    } else if (type == "google_register") {
      return state.googleRegisterErrors;
    }
  },
  getGoogleToken(state) {
    return state.googleToken;
  },
  getIsGoogleLoginClick(state) {
    return state.isGoogleLoginClick;
  },
  getScoreData(state) {
    return state.scoreData;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  setUserData(state, payload) {
    state.userData = payload;
  },
  updateUserData(state, payload) {
    state.userData = payload;
    localStorage.setItem("userData", JSON.stringify(payload));
  },
  clearUserData(state) {
    state.userData = "";
  },
  addError(state, { type, payload }) {
    if (type == "login") {
      state.loginErrors.push(payload);
    } else if (type == "register") {
      state.registerErrors.push(payload);
    } else if (type == "google_login") {
      state.googleLoginErrors.push(payload);
    } else if (type == "google_register") {
      state.googleRegisterErrors.push(payload);
    }
  },
  clearErrors(state) {
    state.loginErrors = [];
    state.registerErrors = [];
    state.googleLoginErrors = [];
    state.googleRegisterErrors = [];
  },
  setGoogleToken(state, payload) {
    state.googleToken = payload;
  },
  setIsGoogleLoginClick(state, payload) {
    state.isGoogleLoginClick = payload;
  },
  setScoreData(state, payload) {
    state.scoreData = payload;
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  initAuth({ commit, dispatch }) {
    let userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
      // set token
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.token}`;
      commit("setUserData", userData);

      // update pro status
      dispatch("updatePremiumStatus");
    } else {
      return false;
    }
  },
  async login({ commit, dispatch, state }, authData) {
    try {
      const response = await axios.post("/api/Login/LoginWithCredentials", {
        usernameOrMail: authData.email,
        password: authData.password,
      });
      let userData = response.data;

      // set token
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.token}`;
      axios
        .get("/api/WordList/GetWordListV2", {
          params: { type: 1, id: userData.knownListId, MeanLanguageType: 2 },
        })
        .then((response_1) => {
          let knownList = response_1.data.words;
          axios
            .get("/api/WordList/GetUserLists?includeWords=false")
            .then((response_2) => {
              let userLists = response_2.data;
              commit("setUserData", {
                ...userData,
                knownList: knownList,
                userWordLists: userLists,
              });
            })
            .finally(() => {
              commit("setEditOption", false);
              commit("setSelectedListId", "");
              commit("setActivePage", "main");
              localStorage.setItem("userData", JSON.stringify(state.userData));
              // update pro status
              dispatch("updatePremiumStatus");
              router.push("/hesabim");
            });
        });
    } catch (error) {
      state.loginErrors.push(error.response["data"]);
    }
  },
  async googleLogin({ commit, dispatch, state }, authData) {
    try {
      const response = await axios.get("/api/Login/ConfirmGoogleAuth", {
        params: { token: authData.token },
      });
      let userData = response.data;

      // set token
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.token}`;

      axios
        .get("/api/WordList/GetWordListV2", {
          params: { type: 1, id: userData.knownListId, MeanLanguageType: 2 },
        })
        .then((response_1) => {
          let knownList = response_1.data.words;
          axios
            .get("/api/WordList/GetUserLists?includeWords=false")
            .then((response_2) => {
              let userLists = response_2.data;
              commit("setUserData", {
                ...userData,
                knownList: knownList,
                userWordLists: userLists,
              });
            })
            .finally(() => {
              commit("setEditOption", false);
              commit("setSelectedListId", "");
              commit("setActivePage", "main");
              localStorage.setItem("userData", JSON.stringify(state.userData));
              // update pro status
              dispatch("updatePremiumStatus");
              router.push("/hesabim");
            });
        });
    } catch (error) {
      state.isGoogleLoginClick = false;
      if (
        error.response["data"] ==
        "Token'da belirtilen mail'e ait bir kayıt bulunamadı"
      ) {
        commit("setGoogleToken", authData.token);
        router.push({ path: "/auth", query: { authLink: "register" } });
      } else {
        state.googleLoginErrors.push(error.response["data"]);
      }
    }
  },
  logOut({ commit, dispatch, state }) {
    commit("clearUserData");
    localStorage.removeItem("userData");
  },
  async register({ commit, dispatch, state }, authData) {
    try {
      const response = await axios
        .post("/api/Register/RegisterWithCredentials", authData)
        .then((response_1) => {
          let userData = response_1.data;
          commit("setUserData", {
            ...userData,
            knownList: [],
            userWordLists: [],
          });
          router.push("/hesabim");
        })
        .finally(() => {
          // set token
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${state.userData.token}`;
          commit("setEditOption", false);
          commit("setSelectedListId", "");
          commit("setActivePage", "main");
          localStorage.setItem("userData", JSON.stringify(state.userData));
        });
    } catch (error) {
      state.registerErrors.push(error.response["data"]);
    }
  },
  async googleRegister({ commit, dispatch, state }, googleData) {
    let authData = {
      token: state.googleToken,
      tokenType: "Google",
      clientType: "web-vue",
      username: googleData.username,
      mailConsent: googleData.consent,
    };
    try {
      const response = await axios
        .post("/api/Register/RegisterWithThirdPartyToken", authData)
        .then((response_1) => {
          let userData = response_1.data;
          commit("setUserData", {
            ...userData,
            knownList: [],
            userWordLists: [],
          });
          router.push("/hesabim");
        })
        .finally(() => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${state.userData.token}`;
          commit("setEditOption", false);
          commit("setSelectedListId", "");
          commit("setActivePage", "main");
          localStorage.setItem("userData", JSON.stringify(state.userData));
        });
    } catch (error) {
      console.log(error);
      state.googleRegisterErrors.push(error.response["data"]);
      // router.push({ path: "/auth", query: { authLink: "register" } });
    }
  },
  async updatePremiumStatus({ commit, dispatch, state }) {
    axios
      .get("/api/User/GetPremiumStatusV2")
      .then((response) => {
        let raw = response.data;
        let premiumEndData = new Date(raw.premiumEndDate);
        let today = new Date(Date.now());

        if (
          (premiumEndData >= today && raw.isPremium) ||
          raw.premiumType == 3
        ) {
          let userData = state.userData;
          userData["isPremium"] = true;
          userData["premiumType"] = raw.premiumType;
          userData["premiumEndDate"] = premiumEndData.toLocaleDateString();
          commit("setUserData", userData);
        } else {
          let userData = state.userData;
          userData["isPremium"] = false;
          commit("setUserData", userData);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch("logOut");
        router.push({ path: "/auth", query: { authLink: "login" } });
      })
      .finally(() => {});
  },
  async updateScoreData({ commit, dispatch, state }) {
    const response = await axios
      .get("/api/Score/GetPointData")
      .catch((error) => {
        console.log(error);
        dispatch("logOut");
        router.push({ path: "/auth", query: { authLink: "login" } });
      });
    if (response) {
      commit("setScoreData", response.data);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
